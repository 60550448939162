//
// Modal styles
//

.modal-content {
  // scss-docs-start modal-css-vars
  --#{$prefix}modal-box-shadow: #{$modal-box-shadow};
  // scss-docs-end modal-css-vars

  border: 0;
  box-shadow: var(--#{$prefix}modal-box-shadow);
}

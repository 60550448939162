//
// Color styles
//

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    --#{$prefix}-bg-opacity: 1;

    background-color: rgba($value, var(--#{$prefix}-bg-opacity));
  }
}

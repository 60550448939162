.wysiwyg-btn {
    background-color: #fbfbfb;
    color: #4f4f4f;
}

.wysiwyg-btn:hover {
    background-color: #eeeeee;
    color: #4f4f4f;
}

.wysiwyg-btn:active, .wysiwyg-btn:focus {
    background-color: #e2e2e2;
    color: #4f4f4f;
}


.stepper-head-icon {
    color: #ffffff;
    background-color: rgb(235, 237, 239);
}

.stepper-completed .stepper-head-icon {
    background-color: rgb(214, 240, 224);
    color: rgb(13, 104, 50);
}
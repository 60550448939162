//
// List group styles
//

.list-group {
  --#{$prefix}list-group-item-transition-time: #{$list-group-item-transition-time};
}

.list-group-item-action {
  transition: var(--#{$prefix}list-group-item-transition-time);

  // Hover state
  &:hover {
    transition: var(--#{$prefix}list-group-item-transition-time);
  }
}

.list-group-light {
  --#{$prefix}list-group-light-item-py: #{$list-group-light-item-py};
  --#{$prefix}list-group-light-item-border: #{$list-group-light-item-border};
  --#{$prefix}list-group-light-item-border-width: #{$list-group-light-item-border-width};
  --#{$prefix}list-group-light-active-border-radius: #{$list-group-light-active-border-radius};
  --#{$prefix}list-group-light-active-bg: #{$list-group-light-active-bg};
  --#{$prefix}list-group-light-active-color: #{$list-group-light-active-color};

  .list-group-item {
    padding: var(--#{$prefix}list-group-light-item-py) 0;
    border: var(--#{$prefix}list-group-light-item-border);
  }
  > .list-group-item {
    border-width: 0 0 var(--#{$prefix}list-group-light-item-border-width);
    &:last-of-type {
      border: none;
    }
  }
  .active {
    border: none;
    border-radius: var(--#{$prefix}list-group-light-active-border-radius);
    background-color: var(--#{$prefix}list-group-light-active-bg);
    color: var(--#{$prefix}list-group-light-active-color);
  }
  .list-group-item-action {
    &:hover {
      border-radius: var(--#{$prefix}list-group-light-active-border-radius);
    }
    &:focus {
      border-radius: var(--#{$prefix}list-group-light-active-border-radius);
    }
  }
}

.list-group-small {
  --#{$prefix}list-group-small-item-py: #{$list-group-small-item-py};
  .list-group-item {
    padding: var(--#{$prefix}list-group-small-item-py) 0;
  }
}

@each $color, $value in $alerts {
  .list-group-item-#{$color} {
    background-color: map-get($value, background-color);
    color: map-get($value, text-color);

    i {
      color: map-get($value, icon-color);
    }
  }
}

.rating {
  --#{$prefix}rating-icon-padding: #{$rating-icon-padding};

  display: flex;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
  i {
    padding: var(--#{$prefix}rating-icon-padding);

    &.fa-sm {
      line-height: 1;
    }
  }
}
.rating:not([data-mdb-readonly='true']) li {
  cursor: pointer;
}

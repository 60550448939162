@mixin mdb-button-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $onLink: map-get($theme, onLink);

  .btn-primary {
    background-color: $primary;
    color: set-notification-text-color($primary);

    &:hover {
      background-color: darken($primary, 7.5%);
      color: set-notification-text-color($primary);
    }

    &:focus,
    &.focus {
      background-color: darken($primary, 7.5%);
      color: set-notification-text-color($primary);
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    .show > &.dropdown-toggle {
      background-color: darken($primary, 20%);
      color: set-notification-text-color($primary);
    }

    &:disabled,
    &.disabled {
      background-color: $primary;
      color: set-notification-text-color($primary);
    }
  }

  .btn-secondary {
    background-color: $secondary;
    color: set-notification-text-color($secondary);

    &:hover {
      background-color: darken($secondary, 7.5%);
      color: set-notification-text-color($secondary);
    }

    &:focus,
    &.focus {
      background-color: darken($secondary, 7.5%);
      color: set-notification-text-color($secondary);
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    .show > &.dropdown-toggle {
      background-color: darken($secondary, 20%);
      color: set-notification-text-color($secondary);
    }

    &:disabled,
    &.disabled {
      background-color: $secondary;
      color: set-notification-text-color($secondary);
    }
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &:focus,
    &.focus {
      color: $primary;
    }

    &:active,
    &.active,
    &.dropdown-toggle.show {
      color: $primary;
    }

    &:disabled,
    &.disabled {
      color: $primary;
    }
  }

  .btn-outline-secondary {
    color: $secondary;
    border-color: $secondary;

    &:hover {
      color: $secondary;
      border-color: $secondary;
    }

    &:focus,
    &.focus {
      color: $secondary;
    }

    &:active,
    &.active,
    &.dropdown-toggle.show {
      color: $secondary;
    }

    &:disabled,
    &.disabled {
      color: $secondary;
    }
  }

  .btn-link {
    color: lighten($primary, 20%);

    &:hover {
      background-color: $onLink;
      color: lighten($primary, 15%);
    }

    &:focus,
    &.focus {
      background-color: $onLink;
    }

    &:active,
    &.active {
      background-color: $onLink;

      &:focus {
        background-color: $onLink;
      }
    }
  }
}

body {
  margin: 0;
  font-family: "Saira Semi Condensed";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

[data-amplify-authenticator] {
  display: flex;
  justify-content: center;
  height: 100vh
}


.react-tel-input .form-control {
  width: 100%!important;
}

.stepperlectures {
  min-height: 2500px;
}

.company_image_container {
  top: 0px;
  position: absolute;
  left: 22%;
  width: fit-content;
  z-index: 1;
}

.button_company_image_upload {
  top: 170px;
  position: absolute;
  left: 35px;
  z-index: 1;
}

.topicsTab {
  width: 50%;
}

.form-control {
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .button_company_image_upload {
    top: 170px;
    position: absolute;
    left: 35px;
    z-index: 1;
  }
  .company_image_container {
    top: 50px;
    position: absolute;
    left: 100px;
    width: fit-content;
    z-index: 1;
  }

  .topicsTab {
    width: unset;
  }
}
@import './mdb.pro.scss';
@import "./fonts.scss";

// Default theme (CP)
$my-theme-primary: #1f3241;
$my-theme-secondary: #ed694b;
$my-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary);
@include mdb-theme($my-theme);

// HD Theme
$hd-theme-primary: #092B5E;
$hd-theme-secondary: #06C63C;
$hd-theme: mdb-light-theme($hd-theme-primary, $hd-theme-secondary);

.hd-theme {
  @include mdb-theme($hd-theme);
  .sidenav-primary .sidenav-link:active, .sidenav-primary .sidenav-link:focus {
    color: $hd-theme-secondary;
    }
    .sidenav-primary .sidenav-item .sidenav-link:hover {
        color: $hd-theme-secondary;
    }
}

// Medical Theme
$med-theme-primary: #2C5282;
$med-theme-secondary: #E53E3E;
$med-theme: mdb-light-theme($med-theme-primary, $med-theme-secondary);

.med-theme {
  @include mdb-theme($med-theme);
}

// DARK SKIN
$dark-theme-primary: #1f3241;
$dark-theme-secondary: #ed694b;
$dark-theme: mdb-dark-theme($dark-theme-primary, $dark-theme-secondary);

.dark {
  @include mdb-theme($dark-theme);
}

.cursor-pointer {
    cursor: pointer;
}

.sidenav-primary .sidenav-link:active, .sidenav-primary .sidenav-link:focus {
    color: $my-theme-secondary;
}
.sidenav-primary .sidenav-item .sidenav-link:hover {
    color: $my-theme-secondary;
}

@font-face {
    font-family: 'SairaSemiCondensed-Regular';
    src: local('SairaSemiCondensed-Regular'), url(./fonts/SairaSemiCondensed-Regular.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

$font-family-base: "Saira Semi Condensed", sans-serif;

body {
    font-family: 'Saira Semi Condensed';
}

html {
    font-family: 'Saira Semi Condensed';
}

main {
    margin: 3.3rem 0 0 0;
    background-color: rgb(31 50 65 / 3%);
    //   this.state.windowWidth > this.state.breakWidth ? "240px" : "0"
}

@media (min-width: 992px) {
    main {
        margin: 3.3rem 0 0 240px;
    }
}

.mainSearch {

}

.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
}

.navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.mainSearch .mainSearch_InputWrapper {
    width: 100%;
    max-width: 500px;
}
@media (min-width: 992px) {
    .mainSearch {
        margin-left: 300px
    }
}

.sidenav {
    overflow: visible;
}



.accordion-features .accordion-button:after {
    content: "\f059";
    font-family: var(--fa-style-family,"Font Awesome 6 Free");
    font-weight: var(--fa-style,900);
    background-image: none;
    width: 1rem;
}

.accordion-features .accordion-button:not(.collapsed)::after {
    content: "\f056";
    font-family: var(--fa-style-family,"Font Awesome 6 Free");
    font-weight: var(--fa-style,900);
    background-image: none;
}

.accordion-slim .accordion-button {
    padding: .5rem 1.5rem !important
}

.bg_cp_logo {
    background-image: url(https://iec-cp-public.s3.eu-central-1.amazonaws.com/Asset+2.svg);
    background-size: 20px;
}

.wysiwyg-content {
    min-height: 200px!important;
}

.navbar .dropdown button, .sidenav .dropdown button, .langSelect button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0.625rem 0.5rem 0.5rem 0.5rem
}


input:-webkit-autofill + label {
    transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
    color: #1f3241;
}

input:-webkit-autofill ~  .form-notch > .form-notch-middle {
    border-right: none;
    border-left: none;
    border-top: 1px solid transparent;
}



.header-template-container {
    padding: 10px;
}

.header-template-date {
    text-align: left;
    line-height: 20px;
}

.header-template-day-name {
    font-weight: bold;
}

.header-template-day {
    font-size: 12px;
}

.header-template-task {
    position: absolute;
    top: 14px;
    right: 10px;
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px;
    border-radius: 12px;
    color: #555;
}

.header-resource-template-content {
    position: relative;
}

.header-resource-avatar {
    max-height: 40px;
    max-width: 40px;
}

.header-resource-name {
    font-size: 14px;
}

.mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day, .mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper {
    display: none;
}

.mbsc-schedule-event-resize {
    color: #000;
}

.mbsc-schedule-col-width, .mbsc-schedule-item {
    min-width: 200px!important;
}

.mbsc-schedule-events.mbsc-ltr {
    left: 1px;
    right: 1px;
}


.cursor-pointer {
    cursor: pointer;
}

.popover-clean {
    background-color: #fff;
    padding-right: 0px;
}
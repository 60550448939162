@mixin mdb-accordion-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $background: map-get($theme, background);
  $onBackground: map-get($theme, onBackground);
  $surfaceHighlight: map-get($theme, surfaceHighlight);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $inputReadonly: map-get($theme, inputReadonly);

  //
  // Base styles
  //

  .accordion-item {
    background-color: $surface;
    border: 1px solid $inputReadonly;
  }
  .accordion-button {
    background-color: $surface;
    color: $onBackground;
  }
  .accordion-button:not(.collapsed) {
    color: $onBackground;
    background-color: $surface;
    -webkit-box-shadow: inset 0 -1px 0 $inputReadonly;
    box-shadow: inset 0 -1px 0 $inputReadonly;
  }
  .accordion-button:after {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/></svg>");
  }
  .accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/></svg>");
  }
  .accordion-button:not(.collapsed):focus {
    -webkit-box-shadow: inset 0 -1px 0 $inputReadonly;
    box-shadow: inset 0 -1px 0 $inputReadonly;
  }
}

// Tooltip

.tooltip {
  --#{$prefix}tooltip-font-size: #{$tooltip-font-size};

  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    display: none;
  }
}

.tooltip-inner {
  font-size: var(--#{$prefix}tooltip-font-size);
}

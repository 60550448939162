@font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Black.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-Black.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Bold.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-Bold.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-ExtraBold.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-ExtraBold.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-ExtraLight.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-ExtraLight.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Light.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-Light.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Medium.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-Medium.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Regular.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-Regular.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-SemiBold.woff2") format("woff2"),
         url("./fonts/SairaSemiCondensed-SemiBold.woff") format("woff"),
         url("./fonts/SairaSemiCondensed-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Saira Semi Condensed";
    src: url("./fonts/SairaSemiCondensed-Thin.woff2") format("woff2"),
    url("./fonts/SairaSemiCondensed-Thin.woff") format("woff"),
    url("./fonts/SairaSemiCondensed-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
  